import 'bootstrap/dist/css/bootstrap.css';

//https://www.npmjs.com/package/react-dropdown-select
import Select from "react-dropdown-select";


import React, { useState } from 'react';
import { Container, Form, Button, Modal, ButtonGroup} from 'react-bootstrap';
// import { Modal } from 'bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { mealPlanner } from './data';

import "../../styles/index.css";

const ToggleSwitch = () => {
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(!selected);
  };

  return (
    <ButtonGroup>
      <Button
        variant={selected ? 'light' : 'success'}
        onClick={handleClick}
        style={{
          backgroundColor: selected ? '#ffffff' : '#00ff00',
          padding: '10px 20px',
          borderRadius: '20px',
          cursor: 'pointer',
          width: '60px',
          textAlign: 'center'
        }}
      >
        {selected ? 'Unselected' : 'Selected'}
      </Button>
    </ButtonGroup>
  );
};

// function ToggleSwitch() {
//   const [isSelected, setIsSelected] = useState(false);

//   return (
//     <div className="custom-control custom-switch">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         id="customSwitch"
//         checked={isSelected}
//         onChange={() => setIsSelected(!isSelected)}
//       />
//       <label className="custom-control-label" htmlFor="customSwitch">
//         Selected
//       </label>
//     </div>
//   );
// }

// const ToggleSwitch = () => {
//   const [state, setState] = useState(false);

//   const handleClick = () => {
//     setState(!state);
//   };

//   return (
//     <ButtonGroup>
//       <Button
//         variant={state ? 'success' : 'danger'}
//         onClick={handleClick}
//         style={{
//           backgroundColor: state ? '#007bff' : '#ff0000',
//           padding: '10px 20px',
//           borderRadius: '20px',
//           cursor: 'pointer',
//         }}
//       >
//         {state ? 'On' : 'Off'}
//       </Button>
//     </ButtonGroup>
//   );
// };



const SearchBar = () => {
  const [search, setSearch] = React.useState("");

  const options = [
      {
        id: 1,
        name: 'Newest'
      },
      {
        id: 2,
        name: 'Oldest'
      }
    ];
    return (
      <>
        <div className="input-group mb-3">
          {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
          <Select style={{width:200, height: 21 }}
            options={options}
            labelField="name"
            valueField="id"
            onChange={(values) => {setSearch(values.id)} }
            placeholder="Sort by: Categories"
          />
        </div>          
      </>
    );
  };

  


const MealPlannerIndex = () => {
    // For Modal
    const [showModal, setShowModal] = React.useState(false);

    const handleCellClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };
    //End Of For Modal
    

  const [mealData, setMealData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleMealChange = (date, meal) => {
    setMealData((prevData) => ({ ...prevData, [date]: meal }));
  };

  const handleSubmit = () => {
    fetch('/api/meal-planner', {
      method: 'POST',
      body: JSON.stringify(mealData),
    });
  };

  return (
    <Container>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          right: 'prev,next',
          left: 'title',
          initialView: "dayGridMonth",
        //   right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        selectable={true}
        select={handleDateSelect}
        editable={true}
        eventClick={handleEventClick}
        events={mealData}
        dayCellContent={({ date }) => {
            const meals = mealData[date.toISOString()];
            return (
              <div>
                <br />
                {meals ? (
                  <ul>
                    {Object.keys(meals).map((meal) => (
                      <li key={meal}>{meal}</li>
                    ))}
                  </ul>
                ) : (
                  <span>Set meal plan</span>
                )}
              </div>
            );
          }
        }


      />



        {/* <Modal show={showModal} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header>
                <Modal.Title style={{ color:"#101840", fontSize:20, fontWeight:600 }}>
                    Picks for today
                    <span style={{ color:"#696F8C", fontSize:16, fontWeight:400 }}><br /> Select the meal of the day to plan</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul style={{listStyle:"none", marginLeft:"-8%" }} >
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} onClick={()=> {alert("hello")} } >Breakfast</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} >Lunch</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Supper</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Snacks</Button>
                    </li>
                </ul>
            </Modal.Body>
        </Modal> */}


        <Modal show={showModal} onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header>
                <Modal.Title style={{ color:"#101840", fontSize:20, fontWeight:600 }}>
                    Lunch
                    <span style={{ color:"#696F8C", fontSize:16, fontWeight:400 }}>
                        <br /> Select the meals for today
                        <SearchBar />
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <table class="table table-sm table-hover table-borderedless">
            <tbody>
                {mealPlanner.map(u => (
                    <tr key={u.key}>
                        <td style={{padding:20, fontSize:14, display:"flex", flexDirection:"row"}}> 
                          <div style={{display:"flex", flexDirection:"row"}} >
                            <img  style={{height:"90%", width:"10%", marginRight:"2%"}} src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg" class="avatar  avatar-sm rounded-circle " alt="user1" />
                            <div>
                              <span style={{fontSize:16, fontWeight:600, color:"#474D66"}} >{u.food}</span>
                              <br /><span style={{fontSize:14, fontWeight:400, color:"#474D66"}}>{u.description}</span>
                            </div>
                          </div>

                          <div style={{fontSize:14, fontWeight:600, color:"#474D66"}}>
                            {u.selectedTxt}
                            <ToggleSwitch />
                          </div>
                        </td>
                    </tr>
                ))}
                
            </tbody>
        </table>

                <ul style={{listStyle:"none", marginLeft:"-8%" }} >
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} onClick={()=> {alert("hello")} } >Breakfast</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }} >Lunch</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Supper</Button>
                    </li>
                    <li>
                        <Button style={{border:"none", background:"none", color:"#000", fontSize:18, fontWeight:500 }}>Snacks</Button>
                    </li>
                </ul>
            </Modal.Body>
        </Modal>



      
      <Form>
        <Form.Group controlId="breakfast">
          <Form.Label>Breakfast</Form.Label>
          <Form.Control
            type="text"
            value={mealData.breakfast}
            onChange={(e) => handleMealChange(selectedDate, { breakfast: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="lunch">
          <Form.Label>Lunch</Form.Label>
          <Form.Control
            type="text"
            value={mealData.lunch}
            onChange={(e) => handleMealChange(selectedDate, { lunch: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="supper">
          <Form.Label>Supper</Form.Label>
          <Form.Control
            type="text"
            value={mealData.supper}
            onChange={(e) => handleMealChange(selectedDate, { supper: e.target.value })}
          />
        </Form.Group>
        <Button onClick={handleSubmit}>Submit Meal Plan</Button>
      </Form>
    </Container>
  );




  function handleDateSelect(arg) {
    setSelectedDate(arg.date);
    alert('hello boy');
    handleCellClick();
  }

  function handleEventClick(arg) {
    setSelectedDate(arg.event.start);
  }
};
 

export default MealPlannerIndex;


