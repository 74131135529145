//https://www.w3schools.com/css/css3_buttons.asp
//Offcanvas Stuff
//https://www.w3schools.com/bootstrap5/tryit.asp?filename=trybs_offcanvas&stacked=h
import 'bootstrap/dist/css/bootstrap.css';
// import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import credpalLogo from "../../assets/credpalLogo.png";
import twitterNav from "../../assets/twitterNav.png";
import instaNav from "../../assets/instaNav.png";
import "../../styles/index.css"


function NavBar() {
    let navigate = useNavigate();

  return (
    <>
        <nav class="mynavbar fixed-top navbar navbar-expand-md bg-light bsb-navbar bsb-navbar-hover bsb-navbar-caret">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
                </a>
                <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>

                <div class=" collapse navbar-collapse ">
                    <ul class="navbar-nav justify-content-center flex-grow-1"></ul>
                    
                    <button style={{backgroundColor:'#30B15C', borderColor:'#30B15C'}}
                            onClick={() =>{ navigate("/waitlist")}} 
                            class="text-white btn btn-lg  rounded-pill fs-6 fw-bold text-secondary" >
                                Join waitlist
                    </button>
                </div>
            </div>
        </nav>


        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div class="offcanvas-header">
                        <a class="navbar-brand" href="/">
                            <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
                        </a>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>

                    <div class="offcanvas-body">
                        <button style={{backgroundColor:'#51D27D', marginTop:"15%", fontSize:14, fontWeight:600, color:"white", width:"50%", padding:"3%", borderRadius:50, border:"none"}}
                                onClick={() =>{ navigate("/waitlist")}} 
                                >
                                    Join waitlist
                        </button>
                        <hr style={{border:"1px solid black"}} />
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <p style={{fontWeight:"bold", fontSize:16}}>
                                Get in touch
                            </p>
                            <div >
                                <a href="https://x.com/livio_food">
                                    <img src={twitterNav} alt="twitter" style={{marginRight:10}} />
                                </a>
                                <a href="https://www.instagram.com/liviofood">
                                    <img src={instaNav} alt="insta"  />
                                </a>
                            </div>
                        </div>
                        
       
                        <p style={{fontWeight:400, fontSize:14, color:"#696F8C", textAlign:"center", marginTop:"15%"}}>© 2024 Livio Inc. All rights reserved.</p>
                    </div>
            </div>





        {/* <-- Show on Mobile, Hide on Laptop ---> */}
        <nav class="mynavbar-mb fixed-top navbar navbar-expand-md bg-light bsb-navbar bsb-navbar-hover bsb-navbar-caret">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
                </a>
                <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>

                <div class=" collapse navbar-collapse ">
                    <ul class="navbar-nav justify-content-center flex-grow-1"></ul>
                    
                    <button style={{backgroundColor:'#30B15C', borderColor:'#30B15C'}}
                            onClick={() =>{ navigate("/waitlist")}} 
                            class="text-white btn btn-lg  rounded-pill fs-6 fw-bold text-secondary" >
                                Join waitlist
                    </button>
                </div>
            </div>
        </nav>


        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <a class="navbar-brand" href="/">
                    <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
                </a>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <button style={{backgroundColor:'#51D27D', marginTop:"15%", fontSize:14, fontWeight:600, color:"white", width:"50%", padding:"3%", borderRadius:50, border:"none"}}
                        onClick={() =>{ navigate("/waitlist")}} 
                        >
                            Join waitlist
                </button>
                <hr style={{border:"1px solid black"}} />
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <p style={{fontWeight:"bold", fontSize:16}}>
                        Get in touch
                    </p>
                    <div >
                        <a href="https://x.com/livio_food">
                            <img src={twitterNav} alt="twitter" style={{marginRight:10}} />
                        </a>
                        <a href="https://www.instagram.com/liviofood">
                            <img src={instaNav} alt="insta"  />
                        </a>
                    </div>
                </div>
                

                <p style={{fontWeight:400, fontSize:14, color:"#696F8C", textAlign:"center", marginTop:"15%"}}>© 2024 Livio Inc. All rights reserved.</p>
            </div>
        </div>

        {/* <-- End of Show on Mobile, Hide on Laptop ---> */}
    </>
  );
}




export default NavBar;



 {/* <ul class="navbar-nav justify-content-center flex-grow-1"> */}
                    
{/*                     
                    <li class="nav-item">
                        <a class="nav-link  fs-5 text-dark" href="#!">All-stars</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link fs-5 text-dark " href="#!">Recipes</a>
                    </li> */}
                    {/* </ul> */}

                    {/* <Link to="/waitlistform"> */}

